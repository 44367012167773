<template>
  <section
    v-if="headingTitle || headingSubtitle || headingPublishedDate || headingDescription"
    class="section page-heading"
    :class="{ 'is-narrow': narrow }"
  >
    <div class="section-inner">
      <h1
        v-if="headingTitle"
        class="main-title size-2xl"
        v-html="headingTitle"
      />
      <h2
        v-if="headingSubtitle"
        class="unbold size-s margin-2xs-top"
        v-html="headingSubtitle"
      />
      <p
        v-if="headingPublishedDate || readingTime"
        class="margin-2xs-top heading-date-time"
      >
        <span v-if="readingTime" class="reading-time">
          <SvgIcon name="func_clock" />
          {{ readingTime }}
        </span>
        <span v-if="headingPublishedDate">
          {{ useMicroCopy("news.published.date.label") }}
          {{ headingPublishedDate }}
        </span>
      </p>
      <div
        v-if="headingDescription"
        class="size-s margin-2xs-top"
        v-html="headingDescription"
      ></div>
    </div>
  </section>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String || Object,
    required: false,
    default: null,
  },
  subtitle: {
    type: String || Object,
    required: false,
    default: null,
  },
  description: {
    type: Object,
    required: false,
    default: null,
  },
  publishedDate: {
    type: String,
    required: false,
    default: null,
  },
  readingTime: {
    type: String,
    required: false,
    default: null,
  },
  narrow: {
    type: Boolean,
    required: false,
  },
});

const headingTitle = computed(() => {
  return typeof props.title === "object"
    ? useRenderRichText(props.title)
    : props.title;
});

const headingSubtitle = computed(() => {
  return typeof props.subtitle === "object"
    ? useRenderRichText(props.subtitle)
    : props.subtitle;
});

const headingDescription = computed(() => {
  return useRenderRichText(props.description);
});

const headingPublishedDate = computed(() => {
  return props.publishedDate ? useFormattedLongDate(props.publishedDate) : null;
});
</script>

<style lang="scss" scoped>
.page-heading {
  padding-top: 1.5rem;
  padding-bottom: 0;
  text-align: center;

  @include for-desktop-up {
    padding-top: 2rem;

    &.is-narrow .section-inner {
      max-width: 56.25rem;
      margin: 0 auto;
      text-align: center;
    }
  }

  .heading-date-time {
    @include for-tablet-portrait-up {
      display: flex;
      align-items: center;
      justify-content: center;
      span + span::before {
        content: "•";
        margin: 0 0.25rem;
      }
    }
  }
  .reading-time {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
  }
}
</style>

<style lang="scss">
.page-heading + .alternate:not(.background-hero-banner),
.page-heading + .background-landing-search-bar {
  margin-top: 5rem;

  @include for-desktop-up {
    margin-top: 7.5rem;
  }
}
</style>
